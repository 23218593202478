var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"form__container",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h6',{staticClass:"text-h6",domProps:{"textContent":_vm._s(_vm.$trans('booking_settings'))}})])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0 mb-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("max_booking_time")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"dense":"","disabled":_vm.disabled,"rules":[_vm.rules.required],"hide-details":"auto","outlined":"","items":_vm.getValues(_vm.defaultMinutesValues, _vm.settings.max_booking_time)},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(data.item > 0)?_c('span',[_vm._v(_vm._s(_vm._f("duration")(data.item)))]):_c('span',[_vm._v(_vm._s(_vm.$trans("no_limit")))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(item > 0)?_c('span',[_vm._v(_vm._s(_vm._f("duration")(item)))]):_c('span',[_vm._v(_vm._s(_vm.$trans("no_limit")))])]}}]),model:{value:(_vm.settings.max_booking_time),callback:function ($$v) {_vm.$set(_vm.settings, "max_booking_time", $$v)},expression:"settings.max_booking_time"}}),_c('span',{staticClass:"text-body-1 dark--text hover"},[_vm._v(" "+_vm._s(_vm.$trans("before_visit"))+". * ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0 mb-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("booking_time_before")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"dense":"","disabled":_vm.disabled,"rules":[_vm.rules.required],"hide-details":"auto","outlined":"","items":_vm.getValues(_vm.defaultMinutesValues, _vm.settings.booking_time_before)},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("duration")(data.item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}}]),model:{value:(_vm.settings.booking_time_before),callback:function ($$v) {_vm.$set(_vm.settings, "booking_time_before", $$v)},expression:"settings.booking_time_before"}}),_c('span',{staticClass:"text-body-1 dark--text hover"},[_vm._v(" "+_vm._s(_vm.$trans("before_visit"))+". * ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0 mb-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("cancel_time_before")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"disabled":_vm.disabled,"rules":[_vm.rules.required],"dense":"","hide-details":"auto","outlined":"","items":_vm.getValues(_vm.defaultMinutesValues, _vm.settings.cancel_time_before)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}}]),model:{value:(_vm.settings.cancel_time_before),callback:function ($$v) {_vm.$set(_vm.settings, "cancel_time_before", $$v)},expression:"settings.cancel_time_before"}}),_c('span',{staticClass:"text-body-1 dark--text hover"},[_vm._v(" "+_vm._s(_vm.$trans("before_visit"))+". * ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0 mb-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("change_time_before")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"disabled":_vm.disabled,"rules":[_vm.rules.required],"dense":"","hide-details":"auto","outlined":"","items":_vm.getValues(_vm.defaultMinutesValues, _vm.settings.change_time_before)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}}]),model:{value:(_vm.settings.change_time_before),callback:function ($$v) {_vm.$set(_vm.settings, "change_time_before", $$v)},expression:"settings.change_time_before"}}),_c('span',{staticClass:"text-body-1 dark--text hover"},[_vm._v(" "+_vm._s(_vm.$trans("before_visit"))+". * ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("slots_every_checkbox_text")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"disabled":_vm.disabled,"dense":"","outlined":"","hide-details":"auto","items":_vm.getValues(_vm.slotsEveryMinutesValues, _vm.settings.slots_every)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}}]),model:{value:(_vm.settings.slots_every),callback:function ($$v) {_vm.$set(_vm.settings, "slots_every", $$v)},expression:"settings.slots_every"}}),_c('span',[_vm._v(" * ")])],1),_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"disabled":_vm.disabled,"label":_vm.$trans('service_additional_break_info'),"hide-details":""},model:{value:(_vm.showBreakOptions),callback:function ($$v) {_vm.showBreakOptions=$$v},expression:"showBreakOptions"}})],1),(_vm.showBreakOptions)?[_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("break_time_before_checkbox_text")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"disabled":_vm.disabled,"dense":"","outlined":"","hide-details":"auto","items":_vm.getValues(_vm.breakTimeDefaultValues, _vm.settings.break_time_before)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}}],null,false,2890760924),model:{value:(_vm.settings.break_time_before),callback:function ($$v) {_vm.$set(_vm.settings, "break_time_before", $$v)},expression:"settings.break_time_before"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("break_time_after_checkbox_text")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"disabled":_vm.disabled,"dense":"","outlined":"","hide-details":"auto","items":_vm.getValues(_vm.breakTimeDefaultValues, _vm.settings.break_time_after)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item))+" ")]}}],null,false,2890760924),model:{value:(_vm.settings.break_time_after),callback:function ($$v) {_vm.$set(_vm.settings, "break_time_after", $$v)},expression:"settings.break_time_after"}})],1)]:_vm._e(),_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"disabled":_vm.disabled,"label":_vm.$trans('service_max_people_info'),"hide-details":""},model:{value:(_vm.enableGroupBookings),callback:function ($$v) {_vm.enableGroupBookings=$$v},expression:"enableGroupBookings"}})],1),(_vm.enableGroupBookings)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans("max_people_title"))+" "),_c('v-text-field',{staticClass:"ml-2",staticStyle:{"max-width":"100px"},attrs:{"rules":[
            _vm.rules.required,
            _vm.rules.minNumber(_vm.settings.max_people, _vm.enableGroupBookings ? 2 : 1),
            _vm.rules.maxNumber(_vm.settings.max_people) ],"disabled":_vm.disabled,"dense":"","type":"number","hide-details":"auto","outlined":""},model:{value:(_vm.settings.max_people),callback:function ($$v) {_vm.$set(_vm.settings, "max_people", $$v)},expression:"settings.max_people"}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"disabled":_vm.disabled,"label":_vm.$trans('max_user_bookings_checkbox'),"hide-details":""},model:{value:(_vm.limitUserBookings),callback:function ($$v) {_vm.limitUserBookings=$$v},expression:"limitUserBookings"}})],1),(_vm.limitUserBookings)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans("max_user_bookings"))+" "),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"dense":"","disabled":_vm.disabled,"rules":[_vm.rules.required],"hide-details":"auto","outlined":"","items":_vm.getValues(_vm.defaultNumbersValues, _vm.settings.max_user_bookings)},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(data.item > 0)?_c('span',[_vm._v(_vm._s(data.item))]):_c('span',[_vm._v(_vm._s(_vm.$trans("no_limit")))])]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [(item > 0)?_c('span',[_vm._v(_vm._s(item))]):_c('span',[_vm._v(_vm._s(_vm.$trans("no_limit")))])]}}],null,false,2724986591),model:{value:(_vm.settings.max_user_bookings),callback:function ($$v) {_vm.$set(_vm.settings, "max_user_bookings", $$v)},expression:"settings.max_user_bookings"}})],1):_vm._e()],2)],1),_c('v-divider',{staticClass:"my-6",attrs:{"light":""}}),_c('v-container',{staticClass:"form__container",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h6',{staticClass:"text-h6",domProps:{"textContent":_vm._s(_vm.$trans('service_payments'))}})])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"rules":[_vm.rules.isBooleanInteger],"disabled":_vm.disabled || !!_vm.settings.only_subscriptions,"false-value":0,"true-value":1,"hide-details":"auto"},model:{value:(_vm.settings.allow_online_payment),callback:function ($$v) {_vm.$set(_vm.settings, "allow_online_payment", $$v)},expression:"settings.allow_online_payment"}}),_c('span',{staticClass:"text-body-1 dark--text hover",on:{"click":function($event){_vm.settings.allow_online_payment === 1
              ? (_vm.settings.allow_online_payment = 0)
              : (_vm.settings.allow_online_payment = 1)}}},[_vm._v(_vm._s(_vm.$trans("allow_online_payment_2")))])],1),(_vm.settings.allow_online_payment && !_vm.isStripeActive)?_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('calendesk-information-message',{attrs:{"color":"orange","additional-class":"white--text my-4","icon-color":"white","icon":"$alert"}},[_vm._v(" "+_vm._s(_vm.$trans("stripe_payments_warning"))+" "),_c('div',{staticClass:"d-flex align-center justify-center ma-2 mt-4"},[_c('v-btn',{attrs:{"color":"white","outlined":"","small":""},on:{"click":_vm.goToStripe}},[_vm._v(" "+_vm._s(_vm.$trans("go_to_stripe"))+" ")])],1)])],1):_vm._e(),_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"rules":[_vm.rules.isBooleanInteger],"disabled":_vm.disabled,"false-value":0,"true-value":1,"hide-details":"auto"},model:{value:(_vm.settings.only_subscriptions),callback:function ($$v) {_vm.$set(_vm.settings, "only_subscriptions", $$v)},expression:"settings.only_subscriptions"}}),_c('span',{staticClass:"text-body-1 dark--text hover",on:{"click":function($event){_vm.settings.only_subscriptions === 1
              ? (_vm.settings.only_subscriptions = 0)
              : (_vm.settings.only_subscriptions = 1)}}},[_vm._v(_vm._s(_vm.$trans("only_subscriptions_label")))])],1)],1),(_vm.settings.allow_online_payment)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('calendesk-information-message',[_vm._v(" "+_vm._s(_vm.$trans("service_info_7"))+" ")])],1),_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"rules":[_vm.rules.isBooleanInteger],"disabled":_vm.disabled || !!_vm.settings.only_subscriptions,"false-value":0,"true-value":1,"hide-details":"auto"},model:{value:(_vm.settings.payment_required),callback:function ($$v) {_vm.$set(_vm.settings, "payment_required", $$v)},expression:"settings.payment_required"}}),_c('span',{staticClass:"text-body-1 dark--text hover",on:{"click":function($event){_vm.settings.payment_required === 1
              ? (_vm.settings.payment_required = 0)
              : (_vm.settings.payment_required = 1)}}},[_vm._v(_vm._s(_vm.$trans("payment_required_2")))])],1),(_vm.settings.payment_required)?_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0 mb-3",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 dark--text"},[_vm._v(_vm._s(_vm.$trans("awaiting_booking_payment_time")))]),_c('v-select',{staticClass:"ml-3 mr-3",staticStyle:{"max-width":"150px"},attrs:{"dense":"","disabled":_vm.disabled,"rules":[_vm.rules.required],"hide-details":"auto","outlined":"","items":_vm.getValues(
              _vm.awaitingBookingTimeDefaultValues,
              _vm.settings.awaiting_booking_payment_time
            )},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(data.item === 0)?_c('span',[_vm._v(_vm._s(_vm.$trans("no_limit")))]):_c('span',[_vm._v(_vm._s(_vm._f("duration")(data.item)))])]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [(item === 0)?_c('span',[_vm._v(_vm._s(_vm.$trans("no_limit")))]):_c('span',[_vm._v(_vm._s(_vm._f("duration")(item)))])]}}],null,false,556584447),model:{value:(_vm.settings.awaiting_booking_payment_time),callback:function ($$v) {_vm.$set(_vm.settings, "awaiting_booking_payment_time", $$v)},expression:"settings.awaiting_booking_payment_time"}}),_c('span',{staticClass:"text-body-1 dark--text hover"},[_vm._v(" * ")])],1):_vm._e()],1):_vm._e(),(_vm.settings.allow_online_payment && !_vm.settings.only_subscriptions)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('calendesk-information-message',[_vm._v(" "+_vm._s(_vm.$trans("invoice_info_1"))+" ")])],1),_c('v-col',{staticClass:"d-flex align-center pt-0 mt-0",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"rules":[_vm.rules.isBooleanInteger],"disabled":_vm.disabled,"false-value":0,"true-value":1,"hide-details":"auto"},model:{value:(_vm.settings.wants_invoice),callback:function ($$v) {_vm.$set(_vm.settings, "wants_invoice", $$v)},expression:"settings.wants_invoice"}}),_c('span',{staticClass:"text-body-1 dark--text hover",on:{"click":function($event){_vm.settings.wants_invoice === 1
              ? (_vm.settings.wants_invoice = 0)
              : (_vm.settings.wants_invoice = 1)}}},[_vm._v(_vm._s(_vm.$trans("wants_invoice_select_title")))])],1)],1):_vm._e(),(_vm.settings.wants_invoice && _vm.settings.allow_online_payment)?_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$trans("tax_amount_title"))+" "),_c('v-select',{staticClass:"ml-2",staticStyle:{"max-width":"150px"},attrs:{"disabled":_vm.disabled,"dense":"","outlined":"","hide-details":"auto","title":_vm.$trans('select'),"append-icon":"$percent","items":_vm.taxValues},model:{value:(_vm.settings.tax),callback:function ($$v) {_vm.$set(_vm.settings, "tax", $$v)},expression:"settings.tax"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }